<script lang="ts" setup>
import qs from 'qs';
import port from '@config/port';
import { ref, watch } from 'vue';
import request from '@utils/request';
import systemInfo from '@/stores/systemInfo';
import projectData from '@/stores/projectData';
import { SYSTEM_INFO } from '@config/constant';
import { onLaunch, onShow } from '@dcloudio/uni-app';
import { checkUpdated } from '@utils/methods';

const mid = ref(''),
	sysInfo = systemInfo(),
	prjData = projectData();

sysInfo.$subscribe((mutation, state) =>
	uni.setStorage({
		key: SYSTEM_INFO,
		data: state,
	})
);

const bindMid = debounce(() => {
	if (sysInfo.token && mid.value) {
		request.get({
			resErr: true,
			showLoad: false,
			params: { mid: mid.value },
			port: port.MEMBER_BINDMEMBER,
		});
	}
}, 300);

onLaunch(({ query }: any) => {
	sysInfo.setSysData(query);

	watch(mid, () => bindMid(), { immediate: true });
	watch(
		() => sysInfo.token,
		(token) => {
			bindMid();
			prjData.fetchConfig('init');
			prjData.fetchUserInfo('init');
			token && prjData.getShareData();
		},
		{ immediate: true }
	);
});

onShow(({ query: { mid: _mid, scene } }: any) => {
	checkUpdated();
	mid.value = _mid;
	if (scene) {
		scene = decodeURIComponent(scene);
		const { mid: _mid2 }: any = qs.parse(scene);
		_mid2 && (mid.value = _mid2);
	}
});
</script>

<style lang="scss">
@import '@climblee/uv-ui/index.scss';

@import '@/assets/styles/public.scss';

</style>
