export const API_BASE: any = {
	SIGN_KEY: 'l]Ybbrj_ZnFz4~9VB7)Gq7[0HGCY]oq{1*I]Eji_e[(~qVRYB#axu+r6IDzeE0*EvX2&BCWt~QmXZc==',
	dev: {
		wap: 'https://wap.cy.940114.xyz',
		api: 'https://api.cy.940114.xyz/Public/boosoov2/',
	},
	prod: {
		wap: 'https://wap.cy.lulublog.cn',
		api: 'https://api.cy.lulublog.cn/Public/boosoov2/',
	},
};
